<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="200px">
            <el-form-item label="导航类型" prop="type">
                <el-select v-model="form.type" placeholder="请选择" :disabled="menu_flag == 'edit_row'">
                    <el-option v-for="(item, index) in menu_type_options" :key="index" :label="item" :value="index" />
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否自动添加权限按钮" prop="restful">
                <el-radio-group v-model="form.restful" :disabled="!(menu_flag != 'edit_row')">
                    <el-radio v-for="(item, index) in restful_options" :key="index" :label="index">{{ item }}</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <el-form-item label="导航名称" prop="permission_name">
                <el-input v-model="form.permission_name" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="后端模块" prop="module">
                <el-input v-model="form.module" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="后端路由" prop="permission_mark">
                <el-input v-model="form.permission_mark" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="后端接口" prop="route">
                <el-input v-model="form.route" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="前端icon" prop="icon">
                <el-input v-model="form.icon" max-length="200" clearable />
            </el-form-item>
            <el-form-item :label="form.type == 1 ? '前端路由' : '按钮key'" prop="component">
                <el-input v-model="form.component" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="跳转地址" prop="redirect" v-if="form.type == 1">
                <el-input v-model="form.redirect" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="隐藏与否" prop="hidden">
                <el-radio-group v-model="form.hidden">
                    <el-radio v-for="(item, index) in hidden_options" :key="index" :label="index">{{ item }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="sort" v-if="form.type == 1">
                <el-input v-model="form.sort" max-length="200" clearable />
            </el-form-item>
            <el-form-item label="父级id" prop="parent_id" v-if="form.type == 1">
                <el-input v-model="form.parent_id" max-length="200" clearable :disabled="true" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitEvent('formRef')" :disabled="disabled_btn">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { http, dict } from '@/assets/js'
export default {
    name: 'MenuAddOrEdit',
    props: {
        menu_info: {
            type: Object,
            default() {
                return {
                    id: '',
                    restful: '',
                    permission_name: '',
                    permission_mark: '',
                    module: '',
                    route: '',
                    icon: '',
                    component: '',
                    redirect: '',
                    hidden: '',
                    sort: '',
                    parent_id: '',
                    type: ''
                }
            }
        },
        menu_flag: {
            type: String,
            default: 'add'
        }
    },
    watch: {
        menu_info: {
            handler(newVal) {
                this.form = newVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            restful_options: dict.menu.menu_restful,
            hidden_options: dict.menu.menu_hidden,
            menu_type_options: dict.menu.menu_type,
            form: {
                id: '',
                restful: '',
                permission_name: '',
                permission_mark: '',
                module: '',
                route: '',
                icon: '',
                component: '',
                redirect: '',
                hidden: '',
                sort: '',
                parent_id: '',
                type: ''
            },
            rules: {
                restful: [
                    {
                        required: true,
                        message: '是否自动添加权限按钮为必选项',
                        trigger: 'blur'
                    }
                ],
                permission_name: [
                    {
                        required: true,
                        message: '导航名称为必填项',
                        trigger: 'blur'
                    }
                ],
                module: [
                    {
                        required: false,
                        message: '前端模块为必填项',
                        trigger: 'blur'
                    }
                ],
                permission_mark: [
                    {
                        required: false,
                        message: '后端路由为必填项',
                        trigger: 'blur'
                    }
                ],
                route: [
                    {
                        required: false,
                        message: '后端接口为必选项',
                        trigger: 'blur'
                    }
                ],
                icon: [
                    {
                        required: false,
                        message: '前端icon为必填项',
                        trigger: 'blur'
                    }
                ],
                component: [
                    {
                        required: false,
                        message: '前端路由为必填项',
                        trigger: 'blur'
                    }
                ],
                redirect: [
                    {
                        required: false,
                        message: '跳转地址为必填项',
                        trigger: 'blur'
                    }
                ],
                hidden: [
                    {
                        required: true,
                        message: '是否隐藏为必填项',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        message: '排序为必填项',
                        trigger: 'blur'
                    }
                ],
                parent_id: [
                    {
                        required: true,
                        message: '父级id为必填项',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '类型为必选项',
                        trigger: 'blur'
                    }
                ]
            },

            disabled_btn: false
        }
    },
    methods: {
        submitEvent(formName) {
            let getdatas = this.form
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.disabled_btn = true

                    let path_route = ''
                    let method = ''
                    if (this.menu_flag == 'add') {
                        path_route = 'admin/permissions'
                        method = 'post'
                    } else if (this.menu_flag == 'edit_row') {
                        path_route = 'admin/permissions/' + this.form.id
                        method = 'put'
                    } else if (this.menu_flag == 'add_row_child') {
                        path_route = 'admin/permissions'
                        method = 'post'
                    }

                    await http[method](path_route, this.form, { sMsg: '操作成功' })
                    this.$emit('close-drawer', true)
                    this.$refs[formName].resetFields()
                    this.disabled_btn = false
                } else {
                    return false
                }
            })
        },
        resetFieldsEvent() {
            this.$refs['formRef'].resetFields()
        }
    }
}
</script>
